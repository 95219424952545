import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  static targets = ["duration"];

  connect() {
    this.timeoutDuration = parseInt(this.durationTarget.value*1000);
    this.startTimeout();
  }

  startTimeout() {
    clearTimeout(this.logoutTimer);
    this.logoutTimer = setTimeout(function() {
      window.location.reload();
    }, this.timeoutDuration);
  }

}